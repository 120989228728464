import FeatureCheck from "../../components/FeatureCheck";
import AlphardSide from "../../images/alphard-side.avif";

// Kelebihan Alphard
const features = [
  {
    title: "Desain Mewah",
    description:
      "Alphard dirancang dengan desain yang mewah dan elegan. Eksteriornya menonjol dengan garis-garis yang halus dan proporsi yang proporsional.",
  },
  {
    title: "Interior Lapang dan Mewah",
    description:
      "Alphard dirancang dengan desain yang mewah dan elegan. Eksteriornya menonjol dengan garis-garis yang halus dan proporsi yang proporsional.",
  },
  {
    title: "Teknologi Canggih",
    description:
      "Alphard dilengkapi dengan berbagai teknologi canggih, termasuk sistem hiburan mutakhir, fitur keamanan tingkat tinggi, dan kontrol iklim yang canggih.",
  },
  {
    title: "Performa Berkendara yang Nyaman",
    description:
      "Alphard dilengkapi dengan berbagai teknologi canggih, termasuk sistem hiburan mutakhir, fitur keamanan tingkat tinggi, dan kontrol iklim yang canggih.",
  },
  {
    title: "Ruang Bagasi Fleksibel",
    description:
      "Alphard memiliki ruang bagasi yang luas dan fleksibel, memungkinkan penumpang untuk membawa barang bawaan dengan mudah.",
  },
  {
    title: "Navigasi dan Sistem Infotainment",
    description:
      "Dilengakapi dengan media player layar sentuh yang dilengkapi fitur navigasi dan integrasi sistem infotainment dengan berbagai perangkat.",
  },
];

export default function Knowledge() {
  return (
    <section className="relative bg-city-gradient bg-slate-100/70 bg-blend-screen bg-opacity-5 w-full min-h-screen flex flex-col md:flex-row pb-20 overflow-hidden">
      <div className="flex flex-col gap-5 w-full md:w-1/3 items-start justify-start text-left text-slate-600 p-10 md:py-20 md:pl-20 bg-gradient-to-b from-slate-200 to-transparent">
        <h2 className="text-3xl poppins-semibold text-slate-700">
          Mengenal Toyota Alphard
        </h2>
        <p>
          <strong>Toyota Alphard</strong> adalah sebuah kendaraan minivan mewah
          yang diproduksi oleh perusahaan otomotif Jepang, Toyota. Alphard
          pertama kali diperkenalkan pada tahun 2002 dan sejak itu telah menjadi
          salah satu model minivan yang paling populer dan dikenal di berbagai
          pasar internasional.
        </p>
        <p>
          <strong>Toyota Alphard</strong> sering menjadi pilihan untuk keperluan
          bisnis, pariwisata, dan keluarga yang mengutamakan kenyamanan dan
          kemewahan dalam berkendara. Kendaraan ini terus mengalami pembaruan
          dan peningkatan fitur dari generasi ke generasi untuk tetap relevan di
          pasar otomotif yang terus berkembang.
        </p>
      </div>
      <div className="flex flex-col gap-5 w-full md:w-2/3 items-start justify-start text-left text-slate-600 p-10 md:py-20 md:pr-20">
        <h2 className="text-3xl poppins-semibold text-slate-700">
          Kelebihan Toyota Alphard
        </h2>

        <div className="grid grid-flow-col grid-cols-1 grid-rows-6 md:grid-cols-2 md:grid-rows-3 gap-5">
          {features.map((feature, index) => (
            <FeatureCheck
              key={index}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>
      </div>
      <div className="absolute -bottom-5 left-0">
        <img
          src={AlphardSide}
          alt="Alphard Side"
          width={300}
          height="auto"
          className="animate-left-to-right-loop"
        />
      </div>
    </section>
  );
}
