import LogoAbipraya from "../../images/clients/Logo ABIPRAYA.avif";
import LogoAdient from "../../images/clients/Logo Adient.avif";
import LogoAts from "../../images/clients/Logo ATS.avif";
import LogoBankBri from "../../images/clients/Logo Bank BRI.avif";
import LogoBata from "../../images/clients/Logo Bata.avif";
import LogoDaesang from "../../images/clients/Logo Daesang.avif";
import LogoFortuna from "../../images/clients/Logo Fortuna.avif";
import LogoHosch from "../../images/clients/Logo Hosch.avif";
import LogoHutamaKarya from "../../images/clients/Logo Hutama Karya.avif";
import LogoIndofarma from "../../images/clients/Logo Indofarma.avif";
import LogoJasaRaharja from "../../images/clients/Logo Jasa Raharja.avif";
import LogoKajima from "../../images/clients/Logo Kajima.avif";
import LogoKemendagri from "../../images/clients/Logo KEMENDAGRI.avif";
import LogoMabesPolri from "../../images/clients/Logo Mabes Polri.avif";
import LogoMedquest from "../../images/clients/Logo Medquest.avif";
import LogoNarasi from "../../images/clients/Logo Narasi.avif";
import LogoPerkasaBetung from "../../images/clients/Logo Perkasa Betung.avif";
import LogoPjbService from "../../images/clients/Logo PJB Service.avif";
import LogoPln from "../../images/clients/Logo PLN.avif";
import LogoPupukIndonesia from "../../images/clients/Logo Pupuk Indonesia.avif";
import LogoSkcLaw from "../../images/clients/Logo SKC Law.avif";
import LogoTppi from "../../images/clients/Logo TPPI.avif";
import LogoTrilliun from "../../images/clients/Logo Trilliun.avif";
import LogoUniversitasIndonesia from "../../images/clients/Logo Universitas Indonesia.avif";
import LogoYakult from "../../images/clients/Logo Yakult.avif";
import LogoYasa from "../../images/clients/Logo YASA.avif";

const clients = [
  LogoAbipraya,
  LogoAdient,
  LogoAts,
  LogoBankBri,
  LogoBata,
  LogoDaesang,
  LogoFortuna,
  LogoHosch,
  LogoHutamaKarya,
  LogoIndofarma,
  LogoJasaRaharja,
  LogoKajima,
  LogoKemendagri,
  LogoMabesPolri,
  LogoMedquest,
  LogoNarasi,
  LogoPerkasaBetung,
  LogoPjbService,
  LogoPln,
  LogoPupukIndonesia,
  LogoSkcLaw,
  LogoTppi,
  LogoTrilliun,
  LogoUniversitasIndonesia,
  LogoYakult,
  LogoYasa,
];

export default function Clients() {
  return (
    <section className="w-full min-h-screen bg-slate-100 flex flex-col items-center justify-center p-10 md:p-20">
      <h2 className="text-3xl poppins-semibold text-slate-700">
        Kami Telah Dipercaya Oleh Jutaan Pelanggan
      </h2>
      <div className="w-full flex flex-wrap gap-5 mt-10 place-content-center items-center justify-start md:justify-center">
        {clients.map((client, index) => (
          <img
            key={index}
            src={client}
            alt="client"
            className="w-1/5 md:w-1/12 h-auto object-contain rounded-lg shadow-md transition-all ease-linear duration-300 hover:scale-110"
          />
        ))}
      </div>
    </section>
  );
}
