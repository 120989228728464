// Thumbnail
import ThumbAlphardFacelift1 from "../../images/galleries/thumbnail-alphard-facelift-front-left.avif";
import ThumbAlphardFacelift2 from "../../images/galleries/thumbnail-alphard-facelift-front-right.avif";
import ThumbAlphardFacelift3 from "../../images/galleries/thumbnail-alphard-facelift-cabin.avif";
import ThumbAlphardFacelift4 from "../../images/galleries/thumbnail-alphard-facelift-dashboard.avif";
import ThumbAlphardFacelift5 from "../../images/galleries/thumbnail-alphard-facelift-passanger.avif";
import ThumbAlphardFacelift6 from "../../images/galleries/thumbnail-alphard-facelift-passanger-display.avif";
import ThumbAlphardTransformer1 from "../../images/galleries/thumbnail-alphard-transformer-front-black.avif";
import ThumbAlphardTransformer2 from "../../images/galleries/thumbnail-alphard-transformer-front-white.avif";
import ThumbAlphardTransformer3 from "../../images/galleries/thumbnail-alphard-transformer-front-wedding.avif";
import ThumbAlphardTransformer4 from "../../images/galleries/thumbnail-alphard-transformer-cabin.avif";
import ThumbAlphardTransformer5 from "../../images/galleries/thumbnail-alphard-transformer-dashboard.avif";
import ThumbAlphardTransformer6 from "../../images/galleries/thumbnail-alphard-transformer-passanger.avif";
import ThumbAlphardG1 from "../../images/galleries/thumbnail-alphard-g2-front.avif";
import ThumbAlphardG2 from "../../images/galleries/thumbnail-alphard-g2-dashboard.avif";
import ThumbAlphardG3 from "../../images/galleries/thumbnail-alphard-g2-cabin.avif";
import ThumbAlphardG4 from "../../images/galleries/thumbnail-alphard-g2-passanger.avif";
import ThumbVideo1 from "../../images/galleries/thumbnail-alphard-facelift.avif";
import ThumbVideo2 from "../../images/galleries/thumbnail-alphard-transformer.avif";
import ThumbVideo3 from "../../images/galleries/thumbnail-alphard-gen2.avif";

// Gallery
import AlphardFacelift1 from "../../images/galleries/alphard-facelift-front-left.avif";
import AlphardFacelift2 from "../../images/galleries/alphard-facelift-front-right.avif";
import AlphardFacelift3 from "../../images/galleries/alphard-facelift-cabin.avif";
import AlphardFacelift4 from "../../images/galleries/alphard-facelift-dashboard.avif";
import AlphardFacelift5 from "../../images/galleries/alphard-facelift-passanger.avif";
import AlphardFacelift6 from "../../images/galleries/alphard-facelift-passanger-display.avif";
import AlphardTransformer1 from "../../images/galleries/alphard-transformer-front-black.avif";
import AlphardTransformer2 from "../../images/galleries/alphard-transformer-front-white.avif";
import AlphardTransformer3 from "../../images/galleries/alphard-transformer-front-wedding.avif";
import AlphardTransformer4 from "../../images/galleries/alphard-transformer-cabin.avif";
import AlphardTransformer5 from "../../images/galleries/alphard-transformer-dashboard.avif";
import AlphardTransformer6 from "../../images/galleries/alphard-transformer-passanger.avif";
import AlphardG1 from "../../images/galleries/alphard-g2-front.avif";
import AlphardG2 from "../../images/galleries/alphard-g2-dashboard.avif";
import AlphardG3 from "../../images/galleries/alphard-g2-cabin.avif";
import AlphardG4 from "../../images/galleries/alphard-g2-passanger.avif";

import Product from "../../components/Product";

const cars = [
  {
    brand: "Toyota",
    model: "Alphard Facelift",
    year: "2018 - 2022",
    colors: "Hitam dan Putih",
    description:
      "Eksterior lebih elegan dan berkelas dengan grill depan dinamis, velg tegas, dan chrome belakang lebih banyak. Interior mewah dengan material kulit, heated seat, dan monitor kabin. Dashboard elegan dengan soft touch, kulit, wood panel, dan setir berlapis kulit. Kursi canggih dan nyaman dengan pengaturan elektrik, penghangat dua level, dan ruang bagasi luas. Fitur keselamatan lengkap dengan Toyota Safety Sense, 7 airbag, ABS + EBD, Vehicle Stability Control, Crash Sensor, dan kamera parkir. Fitur hiburan canggih dengan headunit 2Din, koneksi smartphone, dan layar monitor untuk penumpang baris kedua.",
    price: "Rp 1.300.000",
    video: {
      thumbnail: ThumbVideo1,
      url: "https://www.youtube.com/watch?v=nGgbZwnKGfM&list=PL-bAb8RP2HNlLyG3VPuVzAcYVKATFiyvp&index=11"
    },
    galleries: [
      {
        image: AlphardFacelift1,
        thumbnail: ThumbAlphardFacelift1,
        alt: "Tampak Depan Alphard Facelift",
      },
      {
        image: AlphardFacelift2,
        thumbnail: ThumbAlphardFacelift2,
        alt: "Tampak Depan Alphard Facelift",
      },
      {
        image: AlphardFacelift3,
        thumbnail: ThumbAlphardFacelift3,
        alt: "Kabin Alphard Facelift",
      },
      {
        image: AlphardFacelift4,
        thumbnail: ThumbAlphardFacelift4,
        alt: "Dashboard Alphard Facelift",
      },
      {
        image: AlphardFacelift5,
        thumbnail: ThumbAlphardFacelift5,
        alt: "Kursi Penumpang Alphard Facelift",
      },
      {
        image: AlphardFacelift6,
        thumbnail: ThumbAlphardFacelift6,
        alt: "Layar Penumpang Alphard Facelift",
      },
    ],
  },
  {
    brand: "Toyota",
    model: "Alphard Transformer",
    year: "2016 - 2017",
    colors: "Hitam dan Putih",
    description:
      "Desain depan tajam dan gahar dengan nuansa V Shape. Grille depan berlapis krom, lampu utama besar, dan fog lamp. Lekukan artistik di bagian samping, pintu geser elektrik, dan kaca samping besar. Desain belakang mewah dengan lampu rem besar, grill krom, dan logo Toyota. Dashboard dengan banyak fitur canggih, media hiburan audio visual, dan panel LCD. Setir berbalut wood panel, pengaturan AC dan musik, dan desain V Shape. Kursi berbalut warna krem, fitur pengaturan AC individual, dan meja lipat. Ruang bagasi luas dengan melipat bangku baris ketiga. Fitur keselamatan inovatif: Vehicle Stability Control, bodi depan peredam benturan, 7 airbag, sensor parkir. Fitur keselamatan inovatif: Vehicle Stability Control, bodi depan peredam benturan, 7 airbag, sensor parkir. Fitur hiburan lengkap: audio visual, AC individual, sunroof, dan ambient light",
    price: "Rp 1.000.000",
    video: {
      thumbnail: ThumbVideo2,
      url: "https://www.youtube.com/watch?v=_TGxO5_A6_M&list=PL-bAb8RP2HNlLyG3VPuVzAcYVKATFiyvp&index=10"
    },
    galleries: [
      {
        image: AlphardTransformer1,
        thumbnail: ThumbAlphardTransformer1,
        alt: "Tampak Depan Alphard Transformer Hitam",
      },
      {
        image: AlphardTransformer2,
        thumbnail: ThumbAlphardTransformer2,
        alt: "Tampak Depan Alphard Transformer Putih",
      },
      {
        image: AlphardTransformer3,
        thumbnail: ThumbAlphardTransformer3,
        alt: "Tampak Depan Alphard Transformer Wedding",
      },
      {
        image: AlphardTransformer4,
        thumbnail: ThumbAlphardTransformer4,
        alt: "Kabin Alphard Transformer",
      },
      {
        image: AlphardTransformer5,
        thumbnail: ThumbAlphardTransformer5,
        alt: "Dashboard Alphard Transformer",
      },
      {
        image: AlphardTransformer6,
        thumbnail: ThumbAlphardTransformer6,
        alt: "Kursi Penumpang Alphard Transformer",
      },
    ],
  },
  {
    brand: "Toyota",
    model: "Alphard Generasi 2",
    year: "2014 - 2015",
    colors: "Hitam",
    description:
      "Lebih dinamis dan elegan dibandingkan generasi sebelumnya. Lekukan bodi lebih modern dan luwes. Lampu projector besar, bumper bulat, dan foglamp. Pintu geser elektrik dan spion elektrik. Buritan mewah dengan stoplamp lebar model kristal dan garnish krom. Memadukan kemewahan dan kenyamanan dengan perpaduan warna hitam, beige, dan aksen wood panel. Jok dengan pengaturan elektrik dan suhu, dan AC digital dengan sensor kelembaban. Memberikan ruang kabin yang lebih lega dan nyaman dengan Panoramic sunroof. Kursi captain seat dengan footrests, power leg rests, dan headrests",
    price: "Rp 600.000",
    video: {
      thumbnail: ThumbVideo3,
      url: "https://www.youtube.com/watch?v=MDbMLFyMH6g&list=PL-bAb8RP2HNlLyG3VPuVzAcYVKATFiyvp&index=16"
    },
    galleries: [
      {
        image: AlphardG1,
        thumbnail: ThumbAlphardG1,
        alt: "Tampak Depan Alphard Generasi 2",
      },
      {
        image: AlphardG2,
        thumbnail: ThumbAlphardG2,
        alt: "Dashboard Alphard Generasi 2",
      },
      {
        image: AlphardG3,
        thumbnail: ThumbAlphardG3,
        alt: "Kabin Alphard Generasi 2",
      },
      {
        image: AlphardG4,
        thumbnail: ThumbAlphardG4,
        alt: "Kursi Penumpang Alphard Generasi 2",
      },
    ],
  },
];
export default function Products() {
  return (
    <section className="w-full min-h-screen flex flex-col items-center justify-center text-center bg-white p-10 md:p-20">
      <h2 className="text-3xl poppins-semibold text-slate-700 text-left md:text-center">
        Apa Saja Jenis Mobil Alphard Yang Kami Sewakan?
      </h2>
      <p className="text-slate-600 mt-2 text-left md:text-center">
        Apapun keperluan Anda, dengan moda transportasi yang nyaman dan berkelas
        memberikan kesan yang menakjubkan
      </p>
      <div className="flex flex-col gap-10 mt-10 mx-0 md:mx-20">
        {cars.map((car, index) => (
          <Product
            key={index}
            brand={car.brand}
            model={car.model}
            year={car.year}
            colors={car.colors}
            description={car.description}
            price={car.price}
            video={car.video}
            galleries={car.galleries}
          />
        ))}
      </div>
    </section>
  );
}
