import ModalImage from "./ModalImage";

export default function Product({
  brand,
  model,
  year,
  colors,
  description,
  price,
  video,
  galleries,
}) {
  return (
    <div className="w-full flex flex-col md:flex-row border rounded-lg">
      <div className="w-full md:w-1/2 bg-slate-100 p-5 rounded-l-lg flex flex-col items-start gap-2 flex-1 text-left">
        <h3 className="text-xl poppins-semibold text-slate-700">
          {brand} {model}
        </h3>
        <span className="bg-yellow-500 text-yellow-800 px-2 rounded-lg">
          {year}
        </span>
        <p className="text-slate-600 text-sm">{description}</p>
        <p className="text-slate-600">
          <strong>Warna :</strong> {colors}
        </p>
        <p className="text-slate-600 mt-5">
          Harga sewa mulai dari <br />
          <span className="text-xl poppins-semibold">{price}*</span> Perhari
        </p>
      </div>
      <div className="w-full md:w-1/2 p-5 flex flex-col gap-5">
        <a href={video.url} target="_blank" rel="noreferrer">
          <img src={video.thumbnail} alt={model} />
        </a>

        <div className="w-full h-auto overflow-x-scroll">
          <div className="flex gap-2">
            {galleries.map((gallery, index) => (
              <ModalImage
                small={gallery.thumbnail}
                large={gallery.image}
                alt={gallery.alt}
                key={index} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
