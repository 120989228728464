import Logo from "../../images/Logo-Rainbow-Rent-Car.avif";
import Alphard from "../../images/alphard.avif";

export default function Jumbotron() {
  return (
    <section className="min-h-screen bg-cover bg-bottom bg-heading animate__animated animate__fadeInDown ">
      <div className="absolute z-0 top-0 right-0 w-full h-full bg-gradient-to-br from-slate-900 to-slate-600 opacity-70 bg-blend-multiply"></div>
      <div className="absolute flex flex-col items-start p-10 md:p-20 z-10 top-0 right-0 w-full h-full text-white">
        <header className="">
          <img src={Logo} alt="Logo" width={150} />
        </header>
        <div className="flex h-full flex-col md:flex-row mt-10">
          <div className="w-full md:w-1/2 h-full flex flex-col justify-center gap-5 text-left animate__animated animate__fadeInUp">
            <h1 className="poppins-bold leading-tight text-yellow-500 text-3xl md:text-5xl text-left">
              Ciptakan Momen Tak Terlupakan dengan Sewa Alphard di Jakarta
            </h1>
            <h2 className="poppins-bold text-2xl text-white">
              Mulai Dari{" "}
              <span className="text-yellow-500 text-3xl">600 Ribuan</span>{" "}
              Perhari*
            </h2>
            <p>
              Nikmati pengalaman sewa mobil Alphard dengan driver profesional
              untuk berbagai kebutuhan, mulai dari pernikahan, bisnis, hingga
              wisata.
            </p>
            <a href="/#form" id="cta-top">
              <button className="bg-yellow-500 hover:bg-yellow-600 text-slate-800 poppins-medium py-2 px-4 rounded-lg">
                Pesan Sekarang!
              </button>
            </a>
          </div>
          <div className="w-full md:w-1/2 h-full flex justify-center items-center md:pt-20">
            <img
              src={Alphard}
              alt="Alphard"
              width={800}
              height="auto"
              className="animate-pulse"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
