import IconInstagram from "../images/social/icon-instagram.svg";
import IconFacebook from "../images/social/icon-facebook.svg";
import IconTiktok from "../images/social/icon-tiktok.svg";
import IconYoutube from "../images/social/icon-youtube.svg";
import IconWhatsApp from "../images/social/icon-whatsapp.svg";

export default function Footer() {
    return (
        <>
        <footer className="grid grid-cols-1 md:grid-cols-4 bg-slate-900 w-full p-10 md:p-20 text-slate-50">
            <div className="flex flex-col gap-2">
                <h1 className="text-xl poppins-semibold">PT. Alnajma Selaras Abadi</h1>
                <p className="text-sm">Jl. Raya Setu Kav 10A, Setu, Cipayung, Jakarta Timur 13880</p>
            </div>
            <div className="col-span-2"></div>
            <div className="flex flex-row items-center justify-center gap-5">
                <a href="https://instagram.com/rainbowrentcars" id="social-instagram" target="_blank" rel="noreferrer" className="bg-slate-100 flex items-center justify-center p-2 rounded-full">
                    <img src={IconInstagram} alt="Instagram" width={30} height={30} />
                </a>
                <a href="https://www.facebook.com/rainbowrentcars" id="social-facebook" target="_blank" rel="noreferrer" className="bg-slate-100 flex items-center justify-center p-2 rounded-full">
                    <img src={IconFacebook} alt="Facebook" width={30} height={30} />
                </a>
                <a href="https://www.tiktok.com/@rainbowrentcar" id="social-tiktok" target="_blank" rel="noreferrer" className="bg-slate-100 flex items-center justify-center p-2 rounded-full">
                    <img src={IconTiktok} alt="Tiktok" width={30} height={30} />
                </a>
                <a href="https://www.youtube.com/@rainbowrentcar" id="social-youtube" target="_blank" rel="noreferrer" className="bg-slate-100 flex items-center justify-center p-2 rounded-full">
                    <img src={IconYoutube} alt="Youtube" width={30} height={30} />
                </a>
            </div>
        </footer>
        <div className="fixed bottom-0 right-0 p-10">
            <a href="https://wa.me/6281914400001?text=Halo%20Rainbow" id="cta-float" target="_blank" rel="noreferrer" className="bg-slate-100 border flex items-center justify-center gap-2 py-2 pl-2 pr-5 rounded-full shadow-md">
                <img src={IconWhatsApp} alt="Instagram" width={30} height={30} />
                <span className="text-slate-800 poppins-semibold">Butuh Bantuan?</span>
            </a>
        </div>
        </>
    );
}