import { useState } from "react";

const faqs = [
    {
        title: "Bagaimana Cara Sewa Mobil Di Rainbow Rent Car?",
        content: <div>
                    <p>Berikut adalah prosedur pemesanan sewa mobil di Rainbow Rent Car:</p>
                    <ol className="list-decimal list-inside">
                        <li>Kirim pesan melalui WhatsApp ke Hotline Rainbow Rent Car dengan nomor +62 819 1440 0001.</li>
                        <li>Isi formulir pemesanan yang terdiri dari :
                            <ul className="list-disc list-inside indent-5">
                                <li>Nama Pemesan</li>
                                <li>Nomor Handphone</li>
                                <li>Email</li>
                                <li>Jenis Sewa</li>
                                <li>Jenis Mobil</li>
                                <li>Tanggal Mulai Sewa</li>
                                <li>Tanggal Selesai Sewa</li>
                                <li>Lokasi Penjemputan</li>
                                <li>Jam Penjemputan</li>
                                <li>Rute Perjalanan</li>
                            </ul>
                        </li>
                        <li>Setelah mengisi formulir pemesanan, Customer Service (CS) Rainbow Rent Car akan mengirimkan penawaran biaya sewa.</li>
                        <li>Setelah Anda menyetujui biaya sewa, CS akan mengirimkan detail konfirmasi pemesanan.</li>
                        <li>Setelah detail pemesanan Anda setujui, CS akan mengirimkan Invoice.</li>
                        <li>Lakukan pembayaran DP sebesar 30% melalui transfer bank sesuai dengan yang tertera di Invoice.</li>
                        <li>Kirim bukti pembayaran kepada CS.</li>
                        <li>H-1 sebelum tanggal mulai sewa, lakukan pelunasan dan jangan lupa untuk mengirim bukti transfer ke CS.</li>
                        <li>H-1 CS akan mengirimkan nomor driver dan plat nomor mobil.</li>
                        <li>Di tanggal mulai sewa, driver kami akan stand by di lokasi di tempat dan waktu yang telah diberikan sebelumnya.</li>
                        <li>Setelah masa sewa berakhir, tim kami akan mengirimkan form survey kepuasan pelanggan.</li>
                    </ol>
        </div>,
    },
    {
        title: "Bagaimana Cara Pembayaran Pemesanan Sewa Mobil di Rainbow Rent Car?",
        content: <div>
            <p>Pembayaran biaya sewa dapat dilakukan melalui transfer bank ke:</p>
            <p><strong>BCA a.n PT Alnajma Selaras Abadi</strong></p>
            <p>Nomor Rekening : <strong>6872049343</strong></p>
        </div>
    },
    {
        title: "Berapa Lama Masa Sewa Sewa Mobil Harian Di Rainbow Rent Car?",
        content: <div><p>Untuk sewa mobil harian, durasi sewa adalah 12 jam. Jika masa sewa melebihi 12 jam. Maka akan dikenakan biaya Over Time per jam sebesar 10% dari biaya sewa.</p></div>
    },
    {
        title: "Apakah Bisa Sewa Mobil Lepas Kunci?",
        content: <div><p>Untuk penyewa Alphard perorangan tidak dapat menyewa mobil lepas kunci.
        Jika penyewa berupa perusahaan bisa melakukan sewa Alphard lepas kunci dengan melengkapi persyaratan yang kami butuhkan.</p></div>
    },
    {
        title: "Apakah Sudah Termasuk Dekorasi Bunga, Untuk Sewa Wedding Car?",
        content: <div><p>Ya, setiap sewa wedding car. Mobil yang akan Anda gunakan akan kami dekor secara gratis.</p></div>
    }
]
export default function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);
    return (
        <section className="w-full min-h-screen bg-white flex flex-col items-center justify-center p-10 md:p-20">
            <h2 className="text-3xl poppins-semibold text-slate-700 text-left md:text-center">
                Frequently Asked Questions (FAQ)
            </h2>
            <div className="w-full flex flex-col gap-5 mt-10 mx-0 md:mx-20">
                {faqs.map((faq, index) => (
                    <div className="w-full border rounded-lg p-5" key={index}>
                        <div
                            className="w-full flex justify-between items-center cursor-pointer"
                            onClick={() => activeIndex === index ? setActiveIndex(null) : setActiveIndex(index)}
                        >
                            <h3 className="text-xl poppins-semibold text-slate-700">
                                {faq.title}
                            </h3>
                        </div>
                        {activeIndex === index && <p className="text-slate-600 mt-5">{faq.content}</p>}
                    </div>
                ))}
            </div>
        </section>
    );
}