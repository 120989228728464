import "../../styles/Home.css";
import "animate.css";

import Jumbotron from "./Jumbotron";
import Knowledge from "./Knowledge";
import Functionality from "./Functionality";
import Excellence from "./Excellence";
import Products from "./Products";
import Clients from "./Clients";
import Form from "./Form";
import Footer from "../../layouts/Footer";
import Faq from "./Faq";

export default function Home() {
  return (
    <div className="App w-full">
      <Jumbotron />
      <Knowledge />
      <Functionality />
      <Excellence />
      <Products />
      <Clients />
      <Form />
      <Faq />
      <Footer />
    </div>
  );
}
