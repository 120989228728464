import IconHargaSewaTerjangkau from "../../images/icon-harga-sewa-terjangkau.avif";
import IconJaminanKepuasanPelanggan from "../../images/icon-jaminan-kepuasan-pelanggan.avif";
import IconMobilBersihTerawat from "../../images/icon-mobil-bersih-terawat.avif";
import IconDriverBerpengalaman from "../../images/icon-driver-berpengalaman.avif";
import IconPilihanMobil from "../../images/icon-pilihan-mobil.avif";
import IconDukungan24Jam from "../../images/icon-dukungan-24-jam.avif";
import FeatureBubble from "../../components/FeatureBubble";

const features = [
    {
        src: IconHargaSewaTerjangkau,
        alt: "Harga Sewa Terjangkau",
        title: "Harga Sewa Terjangkau",
    },
    {
        src: IconJaminanKepuasanPelanggan,
        alt: "Jaminan Kepuasan Pelanggan",
        title: "Jaminan Kepuasan Pelanggan",
    },
    {
        src: IconPilihanMobil,
        alt: "Berbagai Pilihan Layanan dan Jenis Mobil",
        title: "Berbagai Pilihan Layanan dan Jenis Mobil",
    },
    {
        src: IconMobilBersihTerawat,
        alt: "Mobil Bersih Terawat",
        title: "Mobil Bersih Terawat",
    },
    {
        src: IconDriverBerpengalaman,
        alt: "Driver Berpengalaman",
        title: "Driver Berpengalaman",
    },
    {
        src: IconDukungan24Jam,
        alt: "Dukungan Pelanggan 24 Jam",
        title: "Dukungan Pelanggan 24 Jam",
    },
]
export default function Excellence() {
    return (
        <section className="w-full min-h-screen bg-slate-200 flex flex-col items-center justify-center p-10 md:p-20">
        <h2 className="text-3xl poppins-semibold text-slate-700">
          Mengapa Sewa Alphard di Rainbow Rent Car?
        </h2>
        <p className="text-slate-600 mt-2">
          Rainbow Rent Car memberikan pelayanan terbaik dan terpercaya dengan harga sewa yang terjangkau.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 md:gap-10 items-center justify-center mt-10">
          {features.map((feature, index) => (
            <FeatureBubble
              key={index}
              src={feature.src}
              alt={feature.alt}
              title={feature.title}
            />
          ))}
        </div>
      </section>
    );
}