import { useState } from "react";

export default function ModalImage({ small, large, alt }) {
    const [active, setActive] = useState(false);
    
    return (
        <>
            <img
                src={small}
                alt={alt}
                width={200}
                height="auto"
                className="cursor-pointer"
                onClick={() => setActive(true)}
            />
            {active &&
            <div className="fixed top-0 left-0 w-full h-screen p-10 flex flex-col gap-5 overflow-y-auto items-center justify-center bg-slate-800/30 backdrop-blur-sm animate__animated animate__zoomIn" onClick={() => setActive(false)}>
                <img
                    src={large}
                    alt={alt}
                    width="auto"
                    height="auto"
                    className="rounded-lg"
                />
                <h4 className="text-slate-800">{alt}</h4>
                <button
                    className="absolute top-5 right-5 text-slate-800 border border-slate-800 rounded-lg px-3 py-1"
                    onClick={() => setActive(false)}
                >
                    Close
                </button>
            </div>
            }
        </>
    );
}