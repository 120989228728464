export default function FeatureImage({ src, alt, title, description }) {
    return (
        <div className="flex flex-col text-left">
          <img
            src={src}
            alt={alt}
            width={300}
            height="auto"
            className="mb-5 rounded-lg"
          />
          <h3 className="text-xl poppins-semibold text-slate-700">
            { title }
          </h3>
          <p className="text-slate-600">
            { description }
          </p>
        </div>
    );
}